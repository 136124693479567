import axios from "axios";
import { 
    ALL_RELATIONS_BY_USER_CODE, 
    BASE_URL, 
    BIOGRAPHY_BY_USER_CODE, 
    DELETE_MEDIA_IMG, 
    GET_USER_DETAILS_BY_USER_CODE, 
    MEDIA_BY_USER_CODE, 
    SAVE_BIOGRAPHY, 
    SAVE_BIOGRAPHY_BY_ID, 
    SAVE_COMMENT,
    SAVE_MEDIA,
    SAVE_MEDIA_VIDE0_BY_ID, 
    SAVE_TIMELINE, 
    SAVE_TIMELINE_BY_ID, 
    SAVE_TRIBUTE, 
    TIMELINE_BY_USER_CODE, 
    TRIBUTE_BY_USER_CODE, 
} from "./api";
import { removeFile, uploadFile, uploadProfileImage } from "./awsS3Upload";

// AUTHENTICATION

// export const getAllRelationsByUserCode = async (id) => {
//     try {
//         const result = await axios.get(`${ALL_RELATIONS_BY_USER_CODE(id)}`);
//         return result.data;

//     } catch (err) {
//         return {
//             error: true,
//             msg: err?.message || 'Get User Code Failed'
//         }
//     }
// }

// SAVE

export const saveBiographyById = async (id, data, user) => {
    // console.log('the data - ', data)
    let url;
    try {
        if (id) {
            url = SAVE_BIOGRAPHY_BY_ID(id);
            const result = await axios.put(`${url}`, {"data": {...data}});
            return result.data;
        } else {
            url = SAVE_BIOGRAPHY
            const result = await axios.post(`${url}`, {"data": {
                ...data,
                "users_permissions_user": user?.id,
                "user_code": user?.user_code?.id
            }});
            return result.data;
        }
    } catch (err) {
        return {
            error: true,
            msg: err?.message || 'Saving Biography Failed'
        }
    }
}


export const saveTimelineById = async (id, data, user) => {
    // console.log('the data - ', data)
    let url;
    try {
        if (id) {
            url = SAVE_TIMELINE_BY_ID(id);
            const result = await axios.put(`${url}`, {"data": {...data}});
            return result.data;
        } else {
            url = SAVE_TIMELINE
            const result = await axios.post(`${url}`, {"data": {
                ...data,
                "users_permissions_user": user?.id,
                "user_code": user?.user_code?.id
            }});
            return result.data;
        }
    } catch (err) {
        return {
            error: true,
            msg: err?.message || 'Saving Timeline Failed'
        }
    }
}

// export const createMediaForUser = async (user) => {
//     try {
//         const result = await axios.post(`${url}`, {"data": {
//             ...data,
//             "users_permissions_user": user?.id,
//             "user_code": user?.user_code?.id
//         }});
//         return result.data;
//     } catch (err) {
//         return {
//             error: true,
//             msg: err?.message || 'Saving Timeline Failed'
//         }
//     }
// }

export const saveComment = async (tribute, data, user, tributeUserCode) => {
    // try {
    //     const result = await axios.post(`${SAVE_COMMENT}`, {"data": {...data}});
    //     return result.data;

    // } catch (err) {
    //     return {
    //         error: true,
    //         msg: err?.message || 'Saving Comment Failed'
    //     }
    // }
    

    // let url;
    // try {
    //     if (tributeId) {
    //         url = SAVE_COMMENT_BY_ID(tributeId);
    //         const result = await axios.put(`${url}`, {"data": {...data}});
    //         return result.data;
    //     } else {
    //         url = SAVE_TRIBUTE
    //         const result = await axios.post(`${url}`, {"data": {
    //             ...data,
    //             "users_permissions_user": user?.id,
    //             "user_code": user?.user_code?.id
    //         }});
    //         return result.data;
    //     }
    // } catch (err) {
    //     return {
    //         error: true,
    //         msg: err?.message || 'Saving Tribute Failed'
    //     }
    // }

    let id = tribute?.id;
    try {
        // let tributeId = data.tribute_id;

        // Step 1: Check if tribute exists for the user
        if (!id) {
            const existingTributes = await axios.get(SAVE_TRIBUTE, {
                params: {
                    filters: {
                        users_permissions_user: user?.id
                    }
                }
            });

            if (existingTributes.data.length > 0) {
                // If tribute exists, get the tribute_id
                id = existingTributes.data[0]?.id;
            } else {
                // Step 2: If no tribute exists, create a new tribute
                const tributeResult = await axios.post(SAVE_TRIBUTE, {
                    "data": {
                        "users_permissions_user": tribute?.ownerId,
                        "user_code": tribute?.ownerUserCodeId,
                        // ...tributeData // Add any necessary tribute fields here
                    }
                })
                id = tributeResult.data?.data?.id;
            }
        }

        // Step 3: Now save the comment with the retrieved or created tribute_id
        const result = await axios.post(SAVE_COMMENT, {
            "data": {
                ...data,
                "tribute": id, // Use the existing or newly created tribute ID
                "users_permissions_user": user?.id,
                "user_code": user?.user_code?.id
            }
        });

        return result.data;
        
    } catch (err) {
        return {
            error: true,
            msg: err?.message || 'Saving Tribute/Comment Failed'
        };
    }

}

// FETCH

export const getAllRelationsByUserCode = async (id) => {
    try {
        const result = await axios.get(`${ALL_RELATIONS_BY_USER_CODE(id)}`);
        return result?.data;

    } catch (err) {
        return {
            error: true,
            msg: err?.message || 'Get User Code Failed'
        }
    }
}

export const getBiographyByUserCode = async (id) => {
    try {
        const result = await axios.get(`${BIOGRAPHY_BY_USER_CODE(id)}`);
        return result?.data;

    } catch (err) {
        return {
            error: true,
            msg: err?.message || 'Get User Code Failed'
        }
    }
}

export const getTimelineByUserCode = async (id) => {
    try {
        const result = await axios.get(`${TIMELINE_BY_USER_CODE(id)}`);
        return result?.data;

    } catch (err) {
        return {
            error: true,
            msg: err?.message || 'Get User Code Failed'
        }
    }
}

export const getMediaByUserCode = async (id) => {
    try {
        const result = await axios.get(`${MEDIA_BY_USER_CODE(id)}`);
        return result?.data;

    } catch (err) {
        return {
            error: true,
            msg: err?.message || 'Get User Code Failed'
        }
    }
}

export const getTributeByCode = async (id) => {
    try {
        const result = await axios.get(`${TRIBUTE_BY_USER_CODE(id)}`);
        const result2 = await axios.get(`${GET_USER_DETAILS_BY_USER_CODE(id)}`);
        const data = {
            ownerId: result2?.data?.data[0]?.attributes?.users_permissions_user?.data?.id,
            ownerUserCodeId: result2?.data?.data[0]?.id,
            userId: result?.data?.data[0]?.id,
            comments: result?.data?.data[0]?.attributes?.comments?.data
        }
        return data;

    } catch (err) {
        return {
            error: true,
            msg: err?.message || 'Get User Code Failed'
        }
    }
}

export const OLD_deleteMediaImgById = async (id) => {
    try {
        const result = await axios.delete(`${DELETE_MEDIA_IMG(id)}`);
        return result?.data;

    } catch (err) {
        return {
            error: true,
            msg: err?.message || 'Delete Failed'
        }
    }
}


export const OLD_saveMediaImageById = async (id, file, user) => {
    const formData = new FormData();
    formData.append("files", file);
    formData.append("ref", "api::image.image");
    formData.append("field", "images");

    try {
        if (id === undefined) {
            const res = await axios.post(`${SAVE_MEDIA}`, {"data": {
                "users_permissions_user": user?.id,
                "user_code": user?.user_code?.id
            }});
            formData.append("refId", res?.data?.id);
            const result = await axios.post(`${BASE_URL}/api/upload`, formData);
            return result.data;
        }
        formData.append("refId", id);
        const result = await axios.post(`${BASE_URL}/api/upload`, formData);
        return result.data;
    } catch (err) {
        return {
            error: true,
            msg: err?.message || 'Saving Image Failed'
        }
    }
}



export const deleteMediaImgById = async (id, index, media) => {
    try {
        const result = await removeFile(id, index, media);
        return result;
    } catch (err) {
        return {
            error: true,
            msg: err?.message || 'Delete Failed'
        }
    }
}

export const saveMediaImageById = async (id, file, user, media) => {
    try {
      // Await the result of the uploadFile function
      const result = await uploadFile(id, file, user, media);
      return result;
    } catch (err) {
      return {
        error: true,
        msg: err?.message || 'Unexpected error occurred',
      };
    }
  };
  
  
export const saveProfileImages = async(type, file, bio, user) => {
    try {
      const result = await uploadProfileImage(type, file, bio, user);
      return result;
    } catch (err) {
      return {
        error: true,
        msg: err?.message || 'Unexpected error occurred',
      };
    }
}



export const saveMediaVideoById = async (id, data, user) => {
    let url;
    try {
        if (id) {
            url = SAVE_MEDIA_VIDE0_BY_ID(id);
            const result = await axios.put(`${url}`, {"data": {...data}});
            return result.data;
        } else {
            url = SAVE_MEDIA
            const result = await axios.post(`${url}`, {"data": {
                ...data,
                "users_permissions_user": user?.id,
                "user_code": user?.user_code?.id
            }});
            return result.data;
        }
    } catch (err) {
        return {
            error: true,
            msg: err?.message || 'Saving Video urls Failed'
        }
    }
}


// const formData = new FormData();
// formData.append("files", file);
// formData.append("ref", "api::image.image");
// formData.append("field", "images");

// try {
//     if (id === undefined) {
//         const res = await axios.post(`${SAVE_MEDIA}`, {"data": {
//             "users_permissions_user": user?.id,
//             "user_code": user?.user_code?.id
//         }});
//         formData.append("refId", res?.data?.id);
//         const result = await axios.post(`${BASE_URL}/api/upload`, formData);
//         return result.data;
//     }
//     formData.append("refId", id);
//     const result = await axios.post(`${BASE_URL}/api/upload`, formData);
//     return result.data;
// } catch (err) {
//     return {
//         error: true,
//         msg: err?.message || 'Saving Image Failed'
//     }
// }