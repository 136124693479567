import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { useAuth } from '../../../context/AuthContext';
import './SignIn.css';
import { Link, useNavigate } from 'react-router-dom';
import LoadingForButton from '../../Common/LoadingForButton';
import Notification from '../../Common/Notification';

import { useTranslation } from 'react-i18next';
import '../../../language/i18n';

const SignIn = () => {
    const { t } = useTranslation();

    const { onLogin } = useAuth();
    const [loading, setLoading] = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false);
    const [userData, setUserData] = useState({
        identifier: "",
        password: ""
    })
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value
        })
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        const result = await onLogin(userData.identifier, userData.password);
        if (result?.error) {
            setError(result.msg);
        } else {
            setSuccess(t("auth.signInSuccessful"))
            setTimeout(() => {
                navigate('/');
            }, 1000);
            // Or you can check sessionStorage if there is a previous search and go to that
        }
        setLoading(false);
    };

  return (
    <section className='page'>
        <div className='container'>
            <h1>{t("auth.signIn")}</h1>
            <form onSubmit={handleLogin}>
                <TextField
                    id="email" 
                    label={t("auth.email")}
                    type="email"
                    name="identifier"
                    value={userData.identifier}
                    onChange={handleChange}
                    required
                    variant="outlined" 
                    InputLabelProps={{ shrink: true }}
                />
                <TextField 
                    id="password" 
                    label={t("auth.password")}
                    type="password"
                    name="password"
                    value={userData.password}
                    onChange={handleChange}
                    required
                    variant="outlined" 
                    InputLabelProps={{ shrink: true }}
                />

                {error && (
                    <Notification
                    type='error'
                    message={error}
                    duration={3000}
                    onClose={() => setError(null)}
                    />
                )}
                {success && (
                    <Notification
                    type='success'
                    message={success}
                    duration={3000}
                    onClose={() => setSuccess(null)}
                    />
                )}

                <button type="submit">{loading ? <LoadingForButton /> : t("auth.signIn")}</button>

                <div className='change-mode'>
                    <p>{t("Don't have an account?")}</p>
                    <Link to='/signup'>{t("auth.signUp")}</Link>
                </div>
                <div className='change-mode'>
                    <Link to='/forgot-password'>{t("Forgot Password")}</Link>
                </div>

                <button className='google'>{googleLoading ? <LoadingForButton /> : t("Continue with Google")}</button>
            </form>
        </div>
    </section>
  );
};

export default SignIn;
