import React, { useEffect, useRef, useState } from 'react';
import './Layout.css';
import Navbar from '../Navbar';
import Tabs from '../Tabs';
import Loading from '../Loading';
import { formatDate } from '../../../helper';
import { getBiographyByUserCode, saveProfileImages } from '../../../service/serviceHelper';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import Reload from '../Reload';
import { PencilOutline } from 'react-ionicons';
import Notification from '../Notification';

// import defaultPhoto from '../../../assets/images/memorialtag-img-default.jpeg';
import photoSample from '../../../assets/images/photo-sample.jpeg';
import coverPhotoSample from '../../../assets/images/coverphoto-sample.jpeg';

import { useTranslation } from 'react-i18next';
import '../../../language/i18n';

export default function Layout(props) {
  const { t } = useTranslation();
  const { userCode, loading, setLoading, biography, setBiography, saving } = props;
  const [bio, setBio] = useState(null);
  const navigate = useNavigate();
  const { authState } = useAuth();
  const [fetchError, setFetchError] = useState(false);
  const [owner, setOwner] = useState(false);
  // const [newFile, setNewFile] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  // const [validationError, setValidationError] = useState(null);
  // const [editing, setEditing] = useState(false);
  const [photoFile, setPhotoFile] = useState(null);
  const [coverPhotoFile, setCoverPhotoFile] = useState(null);
  const [savingP, setSavingP] = useState(false);


  const fetchBiography = async () => {
    const result = await getBiographyByUserCode(userCode);
    if (result.error) {
      setFetchError(true);
    }
    else if (result?.data?.length === 0) {
      navigate('/not-found');
    } else {
      setBio(
        {
          "id": result?.data[0]?.attributes?.biography?.data?.id,
          ...result?.data[0]?.attributes?.biography?.data?.attributes
        }
      )
    }
  }
  useEffect(() => {
    fetchBiography()
  }, [loading])

  
  const pictureFileInputRef = useRef(null);
  const coverPhotoFileInputRef = useRef(null);

  const handleChange = (e) => {
    const name = e.target.name;
    const file = e.target.files[0];
    if (file) {
      const blobUrl = URL.createObjectURL(file);
      if (name === 'picture') {
        setPhotoFile(file);
        handleSave(file, name);
      }
      if (name === 'coverPhoto') {
        setCoverPhotoFile(file);
        handleSave(file, name);
      }
      
    }
  };

  const handleSave = async (file = null, type) => {
    if (bio?.id === undefined) {
      setError(t("biography.addBiographyDetailsFirst"));
      return
    }
    try {
      if (file) {
        setSavingP(true);
        // const formData = new FormData();
        // formData.append("files", file);
        // formData.append("ref", "api::biography.biography");
        // formData.append("refId", bio?.id);
        // formData.append("field", type);

        // await axios.post(`${BASE_URL}/api/upload`, formData);

        await saveProfileImages(type, file, bio, authState?.user)

        // // Delete previous images from db
        // if (type === 'picture' && bio?.picture !== "") {
        //   await deleteMediaImgById(bio?.picture?.data?.id)
        // }
        // if (type === 'coverPhoto' && bio?.coverPhoto !== "") {
        //   await deleteMediaImgById(bio?.coverPhoto?.data?.id)
        // }
        // fetchBiography();
      }
      setSuccess(t('saved successful'));
      fetchBiography()
    } catch (error) {
      setError(t('error saving picture'));
    }
    setSavingP(false);
  };

  useEffect(() => {
    setOwner(userCode === authState?.user?.user_code?.slug);
  }, [authState])

  return (
    <>
      <Navbar />
      {
        loading ? <Loading /> :
        (fetchError ? <Reload setFetchError={setFetchError} setLoading={setLoading} /> :
        <div className='layout'>
          <div className='layout-container'>
            <div className='hero-img'>
              {bio?.coverPhoto !== null ? 
                <img src={`https://memorialtagg.s3.amazonaws.com/coverPhoto/${bio?.coverPhoto}`} className='hero-img' /> :
                <img src={coverPhotoSample} className='hero-img' />
              }

              { owner ?
                <div className='images-container'>
                  <>
                    <input
                      type="file"
                      name='coverPhoto'
                      ref={coverPhotoFileInputRef}
                      onChange={handleChange}
                      style={{ display: 'none' }}
                    />
                    <PencilOutline onClick={() => coverPhotoFileInputRef.current.click()} />
                  </>
                </div>
                :
                null
              }
            </div>

            <div className='hero'>
              <div className='avatar'>
                {bio?.picture !== null ?
                  <img src={`https://memorialtagg.s3.amazonaws.com/picture/${bio?.picture}`} className='avatar' /> :
                  <img src={photoSample} className='avatar-img' />
                }       
                { owner ?
                  <div className='images-container'>
                    <>
                      <input
                        type="file"
                        name='picture'
                        ref={pictureFileInputRef}
                        onChange={handleChange}
                        style={{ display: 'none' }}
                      />
                      <PencilOutline onClick={() => pictureFileInputRef.current.click()} />
                    </>
                  </div>
                  :
                  null
                }
              </div>

              <div className='bio'>
                {/* If names do not exist (i.e no Biography yet, add default) */}
                <h3>{bio?.firstName || t("auth.firstName")} {bio?.lastName || t("auth.lastName")}</h3> 
                {/* Same for birthdate */}
                <p>{`*${formatDate(bio?.birthday) || "01 Jan 2024"}`} &#8211; &#8224;{`${formatDate(bio?.deathday) || "01 Jan 2024"}`}</p>
              </div>
            </div>
            { props.children }
          </div>
          { 
            saving || savingP ?
            <div className='layout-modal'>
                  <Loading />
            </div>
            : 
            null
          }
        </div>)
      }
      {error && <Notification type='error' message={error} duration={3000} onClose={() => setError('')} />}
      {success && <Notification type='success' message={success} duration={3000} onClose={() => setSuccess('')} />}
      <Tabs />
    </>
  );
}
